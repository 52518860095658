<template>
  <div>
    <goals-for-company />
    <goals-for-employer />
  </div>
</template>

<script>
import goalsForCompany from '@/views/goals/goalsForCompany'
import goalsForEmployer from '@/views/goals/goalsForEmployer'

export default {
  components: {
    goalsForCompany,
    goalsForEmployer,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
