<template>
  <div class="overflow-hidden">
    <b-card class="rounded-left rounded-right">
      <b-card-header>
        <b-card-title class="font-large-2">
          <b-row class="d-flex justify-content-start flex-nowrap align-items-center">
            <div>
              Default Employees Goals
            </div>
            <div class="ml-1">
              <feather-icon
                  id="employee-goal-description"
                  size="35"
                  icon="HelpCircleIcon"
              />
            </div>
          </b-row>
          <b-tooltip target="employee-goal-description" placement="right" title="Changing these values is going to overwrite all the individual goals you have set previously. Please, be careful. "></b-tooltip>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" xl="3" class="mb-1">
            <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="search"
            />
          </b-col>
        </b-row>
        <b-form novalidate class="needs-validation" @submit.prevent="submit" autocomplete="off">
          <b-table
              :items="goalsWithEmployees"
              :fields="fields"
              responsive
          >
            <template
                #cell(total_prem_monthly)="data"
            >
              <cleave
                  v-model="data.item.total_prem_monthly"
                  class="form-control"
                  :options="numberInputOptions"
                  placeholder="Total premium monthly"
              />
            </template>
            <template
                #cell(total_prem_weekly)="data"
            >
              <cleave
                  v-model="data.item.total_prem_weekly"
                  class="form-control"
                  :options="numberInputOptions"
                  placeholder="Total premium weekly"
              />
            </template>
            <template
                #cell(op_sold_weekly)="data"
            >
              <cleave
                  v-model="data.item.op_sold_weekly"
                  class="form-control"
                  :options="numberInputOptions"
                  placeholder="Opportunities sold weekly"
              />
            </template>
            <template
                #cell(op_sold_monthly)="data"
            >
              <cleave
                  v-model="data.item.op_sold_monthly"
                  class="form-control"
                  :options="numberInputOptions"
                  placeholder="Opportunities sold monthly"
              />
            </template>
            <template #cell(dont_show_on_tv)="data">
              <b-form-checkbox
                  v-model="data.item.dont_show_on_tv"
                  :value="1"
                  :unchecked-value="0"
                  @change="changeShownOnTvStatus(data.item.name, data.item.dont_show_on_tv)"
              />
            </template>
          </b-table>
          <b-row class="d-flex justify-content-end">
            <b-spinner
              v-if="loading"
            />
            <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
            >
              Submit
            </b-button>
          </b-row>
        </b-form>
        <b-col
            cols="12"
            class="mt-1"
        >
          <b-pagination
              v-if="goalsWithEmployees.length > 0"
              v-model="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @change="pagination"
          />
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol, BForm, BFormInput, BFormInvalidFeedback, BFormRow, BFormValidFeedback, BInputGroup, BInputGroupPrepend,
  BLink,
  BRow, BSkeleton, BSkeletonWrapper, BTable, BPagination, BTooltip, BSpinner, BFormCheckbox
} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import useJwt from "@/auth/jwt/useJwt";
import {analysError} from "@/_helpers/analys_error";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    BCardSubTitle,
    BSkeleton,
    BSkeletonWrapper,
    BFormInput,
    BForm,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,
    BFormCheckbox,
    ToastificationContent
  },
  directives: {
    Ripple
  },
  beforeMount() {
    this.getGoals()
  },
  data() {
    return {
      searchQuery: '',
      loading: false,
      totalPremiumMonthly: null,
      totalPremiumWeekly: null,
      opSoldMonthly: null,
      opSoldWeekly: null,
      numberInputOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      page: 1,
      totalRows: 0,
      perPage: 20,
      goalsWithEmployees: [],
      fields: [
        {
          key: 'name', label: 'Name', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'role', label: 'Role', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'total_prem_weekly', label: 'Total Premium Goal (weekly)', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'total_prem_monthly', label: 'Total Premium Goal (Monthly)', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'op_sold_weekly', label: 'Opportunities Sold (Weekly)', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'op_sold_monthly', label: 'Opportunities Sold (Monthly)', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'dont_show_on_tv', label: "Don't show on the TV screen", sortable: true, tdClass: 'd-flex justify-content-center', thClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    page() {
      this.getGoals()
    }
  },
  methods: {
    changeShownOnTvStatus(name, status) {
      let data = {
        user_name: name,
        dont_show_on_tv: status
      }
      axios.post(`${process.env.VUE_APP_API_URL}/goals/post-employees-goals?only_change_show_tv_status`, data, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {

      }).catch(error => {
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    pagination(value) {
      this.page = value
    },
    getGoals() {
      axios.get(`${process.env.VUE_APP_API_URL}/goals?page=${this.page}&per_page=${this.perPage}`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.goalsWithEmployees = response.data.data.goals
        this.totalRows = response.data.data.total
      }).catch(error => {
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    submit() {
      this.loading = true
      axios.post(`${process.env.VUE_APP_API_URL}/goals/post-employees-goals`, {data: this.goalsWithEmployees}, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.$swal({
          title: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.loading = false
      }).catch(error => {
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    search() {
      axios.get(`${process.env.VUE_APP_API_URL}/goals?search=${this.searchQuery}`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.goalsWithEmployees = response.data.data
      }).catch(error => {
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    }
  },
}
</script>

<style scoped>

</style>