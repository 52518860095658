<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card>
        <b-skeleton width="35%" height="30%" class="m-1"></b-skeleton>
        <b-row>
          <b-col md="3">
            <b-skeleton width="35%" height="30%" class="m-1"></b-skeleton>
          </b-col>
          <b-col md="3">
            <b-skeleton width="35%" height="30%" class="m-1"></b-skeleton>
          </b-col>
          <b-col md="3">
            <b-skeleton width="35%" height="30%" class="m-1"></b-skeleton>
          </b-col>
          <b-col md="3">
            <b-skeleton width="35%" height="30%" class="m-1"></b-skeleton>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <b-card no-body class="rounded-left rounded-right">
      <b-card-header>
        <b-row class="d-flex flex-column m-1">
          <b-card-title class="font-large-2">
            <b-row class="d-flex justify-content-start flex-nowrap align-items-center">
              <div>
                Global Company Goals Setup
              </div>
              <div class="ml-1">
                <feather-icon
                    id="company-goal-description"
                    size="35"
                    icon="HelpCircleIcon"
                />
              </div>
            </b-row>
            <b-tooltip target="company-goal-description" placement="right" title="Please, set up the company goals. By saving changes, numbers on the screen (presentation) will also be changed after the a few minutes. Those goals will be tied to a calendar month and a calendar week (Mon – Sunday).The current goals are set that team should achieve $X per month and $Y weekly (Total Premium), as well as selling Z opportunities per week and H per month"></b-tooltip>
          </b-card-title>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-form novalidate class="needs-validation" @submit.prevent="submit" autocomplete="off">
          <b-form-row>
            <b-col
                md="3"
                class="mb-3"
            >
              <label>Total Premium (monthly)</label>
              <b-input-group prepend="$" append=".00">
                <cleave
                    v-model="totalPremiumMonthly"
                    class="form-control"
                    :options="moneyInputOptions"
                    :state="totalPremiumMonthly > 0"
                    placeholder="X"
                />
              </b-input-group>
              <b-form-valid-feedback tooltip>
                Looks good!
              </b-form-valid-feedback>
              <b-form-invalid-feedback tooltip>
                Write some value!
              </b-form-invalid-feedback>
            </b-col>
            <b-col
                md="3"
                class="mb-3"
            >
              <label>Total Premium (weekly)</label>
              <b-input-group prepend="$" append=".00">
                <cleave
                    v-model="totalPremiumWeekly"
                    class="form-control"
                    :options="moneyInputOptions"
                    :state="totalPremiumWeekly > 0"
                    placeholder="Y"
                />
              </b-input-group>
              <b-form-valid-feedback tooltip>
                Looks good!
              </b-form-valid-feedback>
              <b-form-invalid-feedback
                  tooltip
              >
                Please provide a valid input.
              </b-form-invalid-feedback>
            </b-col>
            <b-col
                md="3"
                class="mb-3"
            >
              <label>Opportunities Sold (Monthly)</label>
              <cleave
                  v-model="opSoldMonthly"
                  class="form-control"
                  :options="moneyInputOptions"
                  placeholder="H"
              />
            </b-col>
            <b-col
                md="3"
                class="mb-3"
            >
              <label>Opportunities Sold (Weekly)</label>
              <cleave
                  v-model="opSoldWeekly"
                  class="form-control"
                  :options="moneyInputOptions"
                  placeholder="Z"
              />
              <b-form-valid-feedback
                  tooltip
              >
                Looks good!
              </b-form-valid-feedback>
              <b-form-invalid-feedback
                  tooltip
              >
                Write some value!
              </b-form-invalid-feedback>
            </b-col>

            <!-- submit button -->
            <b-col
                cols="12"
            >
              <b-spinner
                v-if="blockButton"
              />
              <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
              >
                Submit
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardSubTitle, BCardText, BCardTitle, BCol, BLink,
  BRow, BSkeleton, BSkeletonWrapper, BFormInput, BForm, BFormRow, BFormValidFeedback, BFormInvalidFeedback,
  BInputGroup, BInputGroupPrepend, BTooltip, BSpinner
} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import useJwt from "@/auth/jwt/useJwt";
import {analysError} from "@/_helpers/analys_error";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    BCardSubTitle,
    BSkeleton,
    BSkeletonWrapper,
    BFormInput,
    BForm,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BTooltip,
    BSpinner,
    Cleave
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      totalPremiumMonthly: null,
      totalPremiumWeekly: null,
      opSoldMonthly: null,
      opSoldWeekly: null,
      moneyInputOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      blockButton: false
    }
  },
  beforeMount() {
    this.getGoals()
  },
  methods: {
    getGoals() {
      axios.get(`${process.env.VUE_APP_API_URL}/goals?company`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.totalPremiumMonthly = response.data.data.total_premium_monthly.toString().includes('.')
            ? response.data.data.total_premium_monthly.split('.')[0] : response.data.data.total_premium_monthly
        this.totalPremiumWeekly = response.data.data.total_premium_weekly.toString().includes('.')
            ? response.data.data.total_premium_weekly.split('.')[0] : response.data.data.total_premium_weekly
        this.opSoldMonthly = response.data.data.op_sold_monthly
        this.opSoldWeekly = response.data.data.op_sold_weekly
      }).catch(error => {
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    submit() {
      this.blockButton = true
      let data = {
        company: true,
        total_premium_monthly: this.totalPremiumMonthly.replace(/,/g, ''),
        total_premium_weekly: this.totalPremiumWeekly.replace(/,/g, ''),
        op_sold_monthly: parseInt(this.opSoldMonthly),
        op_sold_weekly: parseInt(this.opSoldWeekly)
      }

      axios.post(`${process.env.VUE_APP_API_URL}/goals`, data, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.$swal({
          title: response.data.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.blockButton = false
      }).catch(error => {
        this.blockButton = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    }
  },
}
</script>

<style scoped>

</style>